<template>
<div class="p-grid p-fluid home " style="height: 800px;">
    <div style="text-align: center; width: 100%; height: 80%;">
        <img alt="components" src="logo.png" width="300" />
        
	</div>


</div>
</template>

<script>
import API from "../service/API";
const Consulta = new API('Cotizacion','Polizas');

export default {
	components: {
    },
	data() {
		return {
            products: [
                {id: 1, titulo: 'Fire Insurance', img: 'HomeInsurance.webp', icon: 'IconHome.webp'},
                {id: 2, titulo: 'Vehicle Insurance', img: 'VehicleInsurance.webp', icon: 'IconVehicle.webp'},
                {id: 3, titulo: 'Health Insurance', img: 'HealthInsurance.webp', icon: 'IconHealth.webp'},
                {id: 4, titulo: 'Business Insurance', img: 'BusinessInsurance.webp', icon: 'IconBusiness.webp'},
                {id: 5, titulo: 'Aviation Insurance', img: 'AviationInsurance.jpeg', icon: 'IconAviation.png'},
                {id: 6, titulo: 'Marine Insurance', img: 'MarineInsurance.jpeg', icon: 'IconMarine.webp'},
            ],
            MensajeDialog: false,
            size: '40vw',
            perfil: null,		
            PerfilDialog: false,
		}
	},
	productService: null,
	eventService: null,
	created() {
        if (this.$store.state.mobile){this.size = '90vw';}
	},
	mounted() {
	},
	methods: {
        Opcion(caso){
            if(caso<5){
                if(this.$store.state.authenticated){
                    this.PerfilDialog = false;
                    Consulta.Ini().then(result => {
                        if(result.consult.perfil){
                            if(this.$store.state.paginas.includes('/quotations')){
                                this.$router.push({ path: '/quotations'+caso});
                            } else {
                                this.$router.push({ path: '/quotation'+caso});
                            }
                        } else  {
                            this.PerfilDialog = true;
                        }
                    }); 
                } else {
                    this.MensajeDialog = true;
                }
            }
        },
	},
	computed: {
    }

}
</script>
